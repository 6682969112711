import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Header } from "semantic-ui-react";
import Page from "../../components/page/Page";

import RequestHelper from "../../RequestHelper";
import InternationalisationService from "../../InternationalisationService";
import { serverAddress } from "../../services/config/EnvConfig";
import AdminModuleRoleEditor from "./AdminModuleRoleEditor";

const AdminModulesEditPage = ({ t }) => {
  const recalculateModules = () => {
    RequestHelper.send(serverAddress + '/modules/refresh',
      {
        "Accept-Language": InternationalisationService.getLanguage(),
      },
      "POST",
      null,
      {}
    );
  }

  return (
    <Page
      name="Admin Questionnaire Module Utilities"
      header={t(
        "ADMIN_QUESTIONNAIRE_MODULE_LINK_HEADER",
        "Admin Questionnaire Modules"
      )}
    >
      <Header>
        {t("MODULE_QUESTIONNAIRE_TITLE", "Module Questionnaire Links")}
      </Header>
      <Button
        type="submit"
        primary
        size="large"
        onClick={recalculateModules}
      >
        {t("QUESTIONNAIRE_MODULE_REFRESH_BUTTON", "Refresh Modules")}
      </Button>
      <Header>
        {t("MODULE_ROLE_LINK_TITLE", "Module Role Links")}
      </Header>
      <AdminModuleRoleEditor />
    </Page>
  )
};

export default withTranslation()(AdminModulesEditPage);
