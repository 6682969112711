import React, { useEffect, useState } from "react";
import {useTranslation, withTranslation} from "react-i18next";
import { Button, Icon, Input, Table } from "semantic-ui-react";
import AdminNewQuestionAnswerModal from "./AdminNewQuestionAnswerModal";
import JsonEditorModal from "../../pages/utils/components/jsoneditor/JsonEditorModal";

const DEFAULT_ANSWER = {
  id: null,
  code: "testCode",
  score: 0,
  sequence: 0,
};

function AdminQuestionAnswerComponent({
  onChange,
  questionDefinition,
  serverTranslations,
  isReadOnly,
}) {
  const {t} = useTranslation();

  const [lastMovedAnswerId, setLastMovedAnswerId] = useState(null);
  const [isModalShowing, setIsModalShowing] = useState(false);

  const [selectedConfig, setSelectedConfig] = useState();

  const [orderedAnswers, setOrderedAnswers] = useState(
    questionDefinition?.answers
      ? questionDefinition.answers.map((q, i) => {
          return { ...q, sequence: i };
        })
      : []
  );

  useEffect(() => {
    setOrderedAnswers(
      questionDefinition?.answers
        ? questionDefinition.answers.map((q, i) => {
            return { ...q, sequence: i };
          })
        : []
    );
  }, [questionDefinition.answers]);

  const changeSequence = async (index, direction) => {
    if (index + direction > orderedAnswers.length || index + direction < 0) {
      return;
    }

    await setLastMovedAnswerId(orderedAnswers[index].id);
    const reorderedAnswers = [...orderedAnswers];
    reorderedAnswers[index].sequence = index + direction;
    reorderedAnswers[index + direction].sequence = index;
    reorderedAnswers.sort((a, b) => a.sequence - b.sequence);
    setOrderedAnswers(reorderedAnswers);
    onChange(reorderedAnswers);
  };

  const changeAnswerScore = (index, value) => {
    const newAnswers = [...orderedAnswers];
    const score = Number.parseFloat(value);
    if (!isNaN(score)) {
      newAnswers[index].score = score;
      onChange(newAnswers);
    }
  };

  const addNewAnswer = (code) => {
    const newAnswers = [...orderedAnswers];
    newAnswers.push({
      ...DEFAULT_ANSWER,
      sequence: orderedAnswers.length,
      code,
    });
    onChange(newAnswers);
  };

  const removeAnswer = (index) => {
    const newAnswers = [...orderedAnswers];
    newAnswers.splice(index, 1);
    onChange(newAnswers);
  };

  const handleConfigEditOpen = (index) => {
    const answer = orderedAnswers[index];
    setSelectedConfig({
      index,
      value: answer?.config
    });
  };

  const handleConfigEditConfirm = (valueAsJsonString) => {

    const updatedAnswers = [...orderedAnswers];
    updatedAnswers[selectedConfig.index].config = JSON.parse(valueAsJsonString);
    onChange(updatedAnswers);
    setSelectedConfig();
  };

  const handleConfigEditCancel = (json) => {
    setSelectedConfig();
  };

  const createAnswerTable = (answers) => {
    if (!answers) {
      return;
    }

    const headers = [
      {
        title: ["", ""],
      },
      {
        title: ["ADMIN_QUESTION_ANSWER_ID", "ID"],
      },
      {
        title: ["ADMIN_QUESTION_ANSWER_CODE", "Code"],
      },
      {
        title: ["ADMIN_QUESTION_ANSWER_LABEL", "Label"],
      },
      {
        title: ["ADMIN_QUESTION_ANSWER_CODE", "Sequence"],
      },
      {
        title: ["ADMIN_QUESTION_ANSWER_SCORE", "Score"],
      },
      {
        title: ["ADMIN_QUESTION_ANSWER_CONFIG", "Config"],
      },
      {
        title: ["", ""],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header, id) => (
          <Table.HeaderCell key={"questionanswerheader"+id}>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );

    const getTranslation = (answer) =>
      serverTranslations.find((sT) =>
        sT.code.includes(
          `questions_${questionDefinition.code}_answers_${answer.code}_label`
        )
      );

    const getConfigButtonText = (answerDefinition) => {
      const jsonString = JSON.stringify(answerDefinition?.config);

      if (jsonString == null || jsonString.length === 0) {
        return t('ADMIN_QUESTION_ANSWER_CONFIG_NOT_SET', 'No config set');
      }

      return jsonString.length < 20
        ? jsonString
        : jsonString.substring(0, 20) + '...';
    }

    const questionRows = answers.map((answer, index) => {
      const label = getTranslation(answer);
      return (
        <Table.Row key={answer.code} positive={answers.id === lastMovedAnswerId}>
          <Table.Cell>
            {!isReadOnly && (
              <Button.Group>
                <Button
                  icon
                  primary
                  disabled={isReadOnly}
                  onClick={() => {
                    changeSequence(index, -1);
                  }}
                >
                  <Icon name="angle up" />
                </Button>
                <Button
                  icon
                  primary
                  disabled={isReadOnly}
                  onClick={() => {
                    changeSequence(index, 1);
                  }}
                >
                  <Icon name="angle down" />
                </Button>
              </Button.Group>
            )}
          </Table.Cell>
          <Table.Cell>{answer.id}</Table.Cell>
          <Table.Cell>{answer.code}</Table.Cell>
          <Table.Cell>{label?.translation}</Table.Cell>
          <Table.Cell>{answer.sequence}</Table.Cell>
          <Table.Cell>
            <Input
              value={answer.score}
              name={"score"}
              type={"number"}
              fluid
              transparent={isReadOnly}
              disabled={isReadOnly}
              onChange={(_e, data) => changeAnswerScore(index, data.value)}
            />
          </Table.Cell>

          <Table.Cell>
            <Button onClick={() => handleConfigEditOpen(index)}>
              {getConfigButtonText(answer)}
            </Button>
          </Table.Cell>

          <Table.Cell>
            {!isReadOnly && (
              <Button
                icon
                color={"red"}
                disabled={isReadOnly}
                onClick={() => {
                  removeAnswer(index);
                }}
              >
                <Icon name="times" />
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <>
        {headerRow}
        {questionRows}
      </>
    );
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <h4 style={{ paddingRight: "1rem" }}>
          {t("ADMIN_QUESTION_ANSWER", "Answers")}
        </h4>
        {!isReadOnly && (
          <Button
            size="small"
            disabled={isReadOnly}
            primary={!isReadOnly}
            onClick={() => setIsModalShowing(true)}
          >
            {t("ADMIN_QUESTION_ANSWER_NEW", "New")}
          </Button>
        )}
      </div>

      <Table>{createAnswerTable(orderedAnswers)}</Table>
      <AdminNewQuestionAnswerModal
        isOpen={isModalShowing}
        setIsOpen={(val) => setIsModalShowing(val)}
        answers={orderedAnswers}
        onConfirm={addNewAnswer}
      />

      <JsonEditorModal 
        title={t('ADMIN_QUESTION_ANSWER_CONFIG_EDITOR_TITLE', 'Configuration')}
        isOpen={selectedConfig != null} 
        value={selectedConfig?.value}
        onConfirm={handleConfigEditConfirm}
        onCancel={handleConfigEditCancel}
      />
    </>
  );
}

export default withTranslation()(AdminQuestionAnswerComponent);
