import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Page from "../../components/page/Page";
import RoleEditComponent from "../../components/roles/RoleEditComponent";
import TrialContext from "../../context/TrialContext";
import RoleService from "../../services/RoleService";
import GroupPermission from "../../GroupPermission";
import { Redirect } from 'react-router-dom';
import PermissionsService from "../../services/PermissionsService";
import StaffService from "../../StaffService";
import UserContext from "../../context/UserContext";
import { Loader } from "semantic-ui-react";

function RolesEditPage({ t }) {
  const params = useParams();
  const [hasPermission, setHasPermission] = useState();
  const [editRole, setEditRole] = useState({ code: '', label: '', override: '', creatableRolesOverride: [], permissions: [], mandatoryTraining: [] });
  const {trial} = useContext(TrialContext);
  const user = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermisisons = async () => {
    const hasCorrectPermission = StaffService.isPrimarySuperAdmin(user?.profile) || await PermissionsService.hasPermissionInAnyGroup(GroupPermission.MANAGE_ROLES);
    setHasPermission(hasCorrectPermission);
    return hasCorrectPermission;
  };

  const init = async () => {
    await checkPermisisons();
  };

  useEffect(() => {
    RoleService.getRole(params.id).then(role => {
      if (role?.mandatoryTraining != null) {
        role.mandatoryTraining = role?.mandatoryTraining.split(",").map(id => parseInt(id));
      }
      setEditRole(role);
    });
  }, [params.id]);

  async function handleSubmit(updatedRole, feedback) {
    await RoleService.updateRole(updatedRole, feedback);
    history.push("/app/roles");
  }

  if (hasPermission === false) {
    return <Redirect to='/' />;
  } else if (hasPermission == null) {
    return <Loader active inline={"centered"} />
  }

  return (<Page
    name="ROLES_EDIT"
    header={t("ROLES_EDIT_HEADER", "Edit Role")}
    subheader={t("ROLES_EDIT_SUBHEADER", "Editing a Role")}
    loading={false}
  >
    <RoleEditComponent
      onSubmit={handleSubmit}
      trial={trial}
      roleToEdit={editRole}
    />
  </Page>);
}

export default withTranslation()(RolesEditPage);
