import _ from "lodash";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';


pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const AttachmentPdfPreview = (props) => {
  const { blob } = props;

  const [numberOfPages, setNumberOfPages] = useState(null);

  const handleOnLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const buildPagesToRender = () => {
    const pageNumbers = _.range(0, numberOfPages);
    const pages = pageNumbers.map((pageNumber) => {
      return <Page key={pageNumber} pageNumber={pageNumber + 1} />;
    });
    return pages;
  };

  const pagesToRender = buildPagesToRender();

  return (
    <Document file={blob} onLoadSuccess={handleOnLoadSuccess}>
      {pagesToRender}
    </Document>
  );
};

export default AttachmentPdfPreview;
