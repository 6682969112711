import React, {useContext, useMemo, useState} from "react";
import {Button, Dropdown, Form, Input} from "semantic-ui-react";
import {QUESTION_TYPES} from "atom5-branching-questionnaire";
import SecondaryButton from "../../generic/SecondaryButton";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import validate from './helpers/questionCodeValidator'
import {useTranslation} from "react-i18next";
import TYPES_DEFAULT_TRANSLATION from "../../../utility/TypesTranslations";
import ConfigContext from "../../../context/ConfigContext";

const DEFAULT_QUESTION = {
  required: true,
  calculated: false,
  hidden: false,
}

function NewQuestionCardComponent({
  isReadOnly = false,
  dispatch,
  questionnaireDefinition,
  toggleOpen
}) {
  const {t} = useTranslation();
  const config = useContext(ConfigContext);


  const typeFilter = ['BOTTOM_BUTTONS', 'AUTO_COMPLETE'];

  const questionTypeOptions = Object.keys(TYPES_DEFAULT_TRANSLATION)
    .filter(qT => !typeFilter.includes(qT))
    .map(qT => {
      const questions = TYPES_DEFAULT_TRANSLATION[qT];
      const textLabel = t(
        questions.labelTranslation ? questions.labelTranslation.translationKey : 'Not Found',
        questions.labelTranslation ? questions.labelTranslation.label : 'Not Found'
      )
      return {
        key: qT,
        text: textLabel,
        value: qT
      };
    });

  const [code, setCode] = useState("");
  const [type, setType] = useState(QUESTION_TYPES.PARAGRAPH);

  const isCodeValid = useMemo(()=>{
    return validate(code, questionnaireDefinition.questions, config?.validationRules?.includes('allowUpperCaseChars'))
  }, [code, questionnaireDefinition.questions, config])

  const handleSave = () => {
    let composedResponse = { ...DEFAULT_QUESTION, code, type, sequence:questionnaireDefinition.questions.length};
    if(type === QUESTION_TYPES.FIXED_VALUE) {
      composedResponse = { ...DEFAULT_QUESTION, code, type, sequence:questionnaireDefinition.questions.length, min:1, max:1 };
    }
    dispatch({
      type: ACTION_TYPES.ADD_QUESTION_DEFINITION,
      payload: composedResponse
    })
    handleReset()
  }
  const handleReset = () => {
    setCode("")
    setType(QUESTION_TYPES.PARAGRAPH)
    toggleOpen && typeof toggleOpen === 'function' && toggleOpen()
  }

  return <div style={{width: "100%", padding: "1rem"}}>
    <Form>
      <Form.Field>
        <label>{t("NEW_QUESTION_DEFINITION_CODE", "Question Code")}</label>
        <Input
            value={code}
            name={"code"}
            error={!isCodeValid && code.length}
            fluid
            onChange={(e,v)=>setCode(v.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("NEW_QUESTION_DEFINITION_TYPE", "Type")}</label>
        <Dropdown
            fluid
            selection
            options={questionTypeOptions}
            value={type}
            name={"type"}
            onChange={(e,v)=>setType(v.value)}
            disabled={isReadOnly}
        />
      </Form.Field>
      <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
        <Button primary={isCodeValid} onClick={handleSave} disabled={!isCodeValid}>{t("NEW_QUESTION_DEFINITION_SAVE", "Save")}</Button>
        <SecondaryButton onClick={handleReset}>{t("NEW_QUESTION_DEFINITION_CANCEL", "Cancel")}</SecondaryButton>
      </div>
    </Form>
  </div>
}

export default NewQuestionCardComponent;
