import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import RoleEditComponent from "../../components/roles/RoleEditComponent";
import TrialContext from "../../context/TrialContext";
import RoleService from "../../services/RoleService";
import { useHistory } from "react-router-dom";
import StaffService from "../../StaffService";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import { Redirect } from 'react-router-dom';
import UserContext from "../../context/UserContext";
import { Loader } from "semantic-ui-react";

function RolesNewPage({ t }) {
  const [hasPermission, setHasPermission] = useState();
  const {trial} = useContext(TrialContext);
  const user = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermisisons = async () => {
    const hasCorrectPermission = StaffService.isPrimarySuperAdmin(user?.profile) || await PermissionsService.hasPermissionInAnyGroup(GroupPermission.MANAGE_ROLES);
    setHasPermission(hasCorrectPermission);
    return hasCorrectPermission;
  };

  const init = async () => {
    await checkPermisisons();
  };

  async function handleSubmit(newRole, feedback) {
    await RoleService.newRole(newRole, feedback);
    history.push("/app/roles");
  }

  if (hasPermission === false) {
    return <Redirect to='/' />;
  } else if (hasPermission == null) {
    return <Loader active inline={"centered"} />
  }

  return (<Page
    name="ROLES_NEW"
    header={t("ROLES_NEW_HEADER", "New Role")}
    subheader={t("ROLES_NEW_SUBHEADER", "Create a new Role")}
    loading={false}
    >
    <RoleEditComponent
      onSubmit={handleSubmit}
      trial={trial}
      roleToEdit={{ code: '', label: '', override: '', creatableRolesOverride: [], permissions: [] }}
    />

  </Page>);
}

export default withTranslation()(RolesNewPage);
