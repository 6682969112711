import RequestHelper, { CONTENT_TYPE } from "./RequestHelper";
import InternationalisationService from "./InternationalisationService";
import moment from "moment";
import DateTimeService from "./services/DateTimeService";
import TrialService from "./TrialService";
import AuthService from "./services/AuthService";
import {serverAddress} from "./services/config/EnvConfig";

export default class SubjectService {
  static recordConsentLanding(subjectId, requestBody) {
    return RequestHelper.send(
        serverAddress +
        "/selfonboarding/subjects/consent/landing/" +
        subjectId,
      {},
      "POST",
      null,
      requestBody
    );
  }
  static selfOnboardSubject(subjectId, requestBody) {
    return RequestHelper.send(
        serverAddress +
        "/selfonboarding/subjects/external/" +
        subjectId,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      requestBody
    );
  }

  static selfOnboardSubjectSubmitScreeningQuestionnaires(requestBody) {
    return RequestHelper.send(
        serverAddress + "/subjects/screeningquestions",
      {},
      "POST",
      null,
      requestBody
    );
  }

  static createSubject(groupCodes, subjectData, subjectNumber = null, staffEmail = null) {
    const requestBody = { groupCodes, subjectData, subjectNumber, staffEmail };
    return RequestHelper.send(
        serverAddress + "/subjects",
      {},
      "POST",
      null,
      requestBody
    );
  }

  static addCreateSubjectPermission(groupCode) {
    return RequestHelper.send(
        serverAddress + "/subjects/addCreateSubjectPermission/" + groupCode,
        {},
        "GET",
        null
    );
  }

  static getSubjectContext() {
    return RequestHelper.send(
        serverAddress +'/subjects/subject-context',
        {},
        "GET",
        );
  }

  static getSubjectContextAsStaff(subjectId, pathArray) {
    console.log(pathArray.toString())
    return RequestHelper.send(
        serverAddress +'/subjects/'+subjectId+'/subject-context?paths=' + pathArray.toString(),
        {},
        "GET",
        );
  }

  static updateSubjectRecords(subjectId, subjectData) {
    const requestBody = { subjectData };
    return RequestHelper.send(
      serverAddress + "/subjects/" + subjectId,
      {},
      "POST",
      null,
      requestBody
    );
  }

  static updateSubjectGroups(subjectId, groupCodes) {
    const requestBody = { groupCodes };
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/groups",
      {},
      "POST",
      null,
      requestBody
    );
  }

  static updateSubjectEmail(subjectId, email) {
    const requestBody = { email };
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/email",
      {},
      "POST",
      null,
      requestBody
    );
  }

  static updateSubjectCode(subjectId, subjectCode, feedbackReason) {
    const feedbackHeaderValue = feedbackReason
      ? `Updating Trigger ${subjectId}'s code to be ${subjectCode}`
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );
    const requestBody = { ...initialRequestBody, subjectCode};
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/subjectCode",
      headers,
      "POST",
      null,
      requestBody
    );
  }

  static generateNewSubjectPassword(subjectId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/generate-new-password",
      {},
      "POST",
      null,
      null
    );
  }

  static updateSubjectLanguage(subjectId, language, feedback) {
    const requestBody = { language, feedback };
    let feedbackHeader = feedback
      ? { "x-feedback-available": "Changing subject language" }
      : {};
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/language",
      feedbackHeader,
      "POST",
      null,
      requestBody
    );
  }

  static updateSubjectActive(subjectId, active, feedback) {
    const requestBody = { active, feedback };
    let feedbackHeader = feedback
      ? { "x-feedback-available": "Stopping subject study" }
      : {};
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/active",
      feedbackHeader,
      "POST",
      null,
      requestBody
    );
  }

  static listSubjects(
    groupCode,
    page,
    searchQuery,
    sortBy,
    sortDirection,
    pageLimit,
    filters,
    subjectSates,
    includeStopped,

  ) {
    let queryParams = {};
    if (searchQuery) {
      queryParams.searchQuery = searchQuery;
    }
    if (groupCode) {
      queryParams.groupCode = groupCode;
    }
    if (page) {
      queryParams.page = page;
    }
    if (sortBy) {
      queryParams.sortBy = sortBy;
    }
    if (sortDirection) {
      queryParams.sortDirection = sortDirection;
    }
    if (pageLimit) {
      queryParams.pageLimit = pageLimit;
    }
    if (filters) {
      queryParams.filters = filters;
    }
    if (subjectSates) {
      queryParams.subjectStates = subjectSates;
    }
    if (includeStopped) {
      queryParams.includeStopped = includeStopped;
    }

    return RequestHelper.send(
      serverAddress + "/subjects",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
  }

  static listSubjectIds(
      groupCode,
      page,
      searchQuery,
      sortBy,
      sortDirection,
      pageLimit,
      filters,
      subjectStates,
      includeStopped
  ) {
    let queryParams = {};
    if (searchQuery) {
      queryParams.searchQuery = searchQuery;
    }
    if (groupCode) {
      queryParams.groupCode = groupCode;
    }
    if (page) {
      queryParams.page = page;
    }
    if (sortBy) {
      queryParams.sortBy = sortBy;
    }
    if (sortDirection) {
      queryParams.sortDirection = sortDirection;
    }
    if (pageLimit) {
      queryParams.pageLimit = pageLimit;
    }
    if (filters) {
      queryParams.filters = filters;
    }
    if (subjectStates) {
      queryParams.subjectStates = subjectStates;
    }
    if (includeStopped) {
      queryParams.includeStopped = includeStopped;
    }

    return RequestHelper.send(
        serverAddress + "/subjects/listSubjectIds",
        { "Accept-Language": InternationalisationService.getLanguage() },
        "GET",
        queryParams
    );
  }

  static getSubjectsCount(groupCode, page, searchQuery, filters, subjectStates, includeStopped) {
    let queryParams = {};
    if (searchQuery) {
      queryParams.searchQuery = searchQuery;
    }
    if (groupCode) {
      queryParams.groupCode = groupCode;
    }
    if (page) {
      queryParams.page = page;
    }
    if (filters) {
      queryParams.filters = filters;
    }
    if (subjectStates) {
      queryParams.subjectStates = subjectStates;
    }
    if (includeStopped) {
      queryParams.includeStopped = includeStopped
    }

    return RequestHelper.send(
      serverAddress + "/subjects/count",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
  }

  static getSubjectRecordQuestionnaireDefinitions() {
    const queryParams = { type: "SUBJECT_RECORD" };
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
  }

  static getSubjectDataQuestionnaireDefinitions() {
    const queryParams = { type: "DATA" };
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
  }

  static getQuestionnaireDefinitions(type) {
    type = type.toUpperCase();
    return RequestHelper.send(
      serverAddress + "/questionnaire-definitions",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      { type }
    );
  }

  static getSubjectProsAsExcel(subjectId, questionnaireDefinitionId, language) {
    return RequestHelper.send(
      `${serverAddress}/export-v2/questionnaire/type/${questionnaireDefinitionId}/subject/${subjectId}/xls/${language}`,
      {
        Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
        "Keep-Alive": "Keep-Alive: timeout=600, max=1000",
      },
      "GET"
    );
  }

  static getSubjectData(subjectId) {
    if (subjectId === -1) { 
      // Don't try and hit the endpoint if the subject id is -1, which we use as a control for new subjects which are not yet saved in the DB
      return;
    }
    return RequestHelper.send(
      serverAddress + "/subjects/" + subjectId,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getSubjectGroups(subjectId) {
    if (subjectId === -1) { 
      // Don't try and hit the endpoint if the subject id is -1, which we use as a control for new subjects which are not yet saved in the DB
      return;
    }
    return RequestHelper.send(
      serverAddress + "/subjects/" + subjectId +"/groups",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getSubjectGroup(groups) {
    if (!groups || !Array.isArray(groups)) {
      console.warn('[SubjectService][getSubjectGroup] No groups');
      return;
    }
    if (groups.length > 1) {
        // This is a weird thing to handle, and needs looking at system wide - but for now this code will work in the same way as pre-existing code where the same issue is present
        console.warn('[SubjectService][getSubjectGroup] Multiple groups, will use first.');
    }
    return groups[0];
  }

  static getSubjectPreferences() {
    return RequestHelper.send(
      serverAddress + "/subjects/preferences",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static updateSubjectPreferencesLanguage(language) {
    const requestBody = { language };
    return RequestHelper.send(
      serverAddress + "/subjects/preferences/language",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      requestBody
    );
  }

  static getSubjectVitalSignsData(subjectId, startDate, endDate) {
    const queryParams = { from: startDate, to: endDate };
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/data/vital-signs",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
  }

  static getSubjectPermission(subjectId) {
    if (subjectId === -1) { 
      // Don't try and hit the endpoint if the subject id is -1, which we use as a control for new subjects which are not yet saved in the DB
      return;
    }
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/permissions"
    );
  }

  static getSubjectPermissionsBulk(subjectIds) {
    return RequestHelper.send(
      serverAddress + "/subjects/permissions/bulk",
      undefined,
      "POST",
      undefined,
      subjectIds
    );
  }

  static getSubjectDevices(subjectId) {
    const queryParams = { subjectId };
    return RequestHelper.send(
      serverAddress + "/devices",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
  }

  static getIsValidQrCode(deviceId) {
    return RequestHelper.send(
      serverAddress + "/devices/" + deviceId + "/isvalidqrcode",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET"
    );
  }

  static getMedicationList(subjectId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/medication"
    );
  }

  static createMedication(subjectId, payload, headers = {}) {
    const requestBody = { subjectId, ...payload };
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/medication",
      headers,
      "POST",
      null,
      requestBody
    );
  }

  static getMedication(subjectId, medicationId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/medication/" +
        medicationId
    );
  }

  static editMedication(subjectId, medicationId, payload, headers = {}) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/medication/" +
        medicationId,
      headers,
      "POST",
      null,
      payload
    );
  }

  static getMedicationCompliance(subjectId, from, to) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/data/medication/compliance",
      {},
      "GET",
      { from, to }
    );
  }

  static getMedicationNotTaken(subjectId, from, to) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/data/medication/not-taken",
      {},
      "GET",
      { from, to }
    );
  }

  static getAdhocMedication(subjectId, from, to) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/data/medication/adhoc",
      {},
      "GET",
      { from, to }
    );
  }

  static deleteMedication(subjectId, medicationId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/medication/" +
        medicationId,
      {},
      "DELETE"
    );
  }

  static getAllergyList(subjectId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/allergies"
    );
  }

  static createAllergy(subjectId, payload, headers = {}) {
    const requestBody = { subjectId, ...payload };
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/allergies",
      headers,
      "POST",
      null,
      requestBody
    );
  }

  static getAllergy(subjectId, allergyId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/allergies/" +
        allergyId
    );
  }

  static editAllergy(subjectId, allergyId, payload, headers = {}) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/allergies/" +
        allergyId,
      headers,
      "POST",
      null,
      payload
    );
  }

  static deleteAllergy(subjectId, allergyId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/allergies/" +
        allergyId,
      {},
      "DELETE"
    );
  }

  static updateFirmwareUpgradeStatus(deviceId, payload) {
    return RequestHelper.send(
      serverAddress +
        "/devices/" +
        deviceId,
        {},
        "PUT",
        null,
        payload
    );
  }

  static updateDeviceState(deviceId, payload,feedbackReason) {
    const feedbackHeaderValue = feedbackReason
        ? `Updating deviceState [${deviceId}] to [${payload.state}]`
        : undefined;

    const { initialRequestBody, initialHeaders: headers } =
        RequestHelper.createInitialRequestObjectsWithFeedback(
            feedbackReason,
            feedbackHeaderValue
        );
    const requestBody = { ...initialRequestBody, ...payload};
    return RequestHelper.send(
        serverAddress +
        "/devices/state/" +
        deviceId,
        headers,
        "PUT",
        null,
        requestBody
    );
  }

  static createDevice(subjectId, description, feedback, headers = {}) {
    let feedbackHeader = feedback
        ? { "x-feedback-available": "Adding new Subject Device" }
        : {};
    headers = {
      ...feedbackHeader,
      ...headers
    }

    const requestBody = { subjectId, description, feedback };

    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/devices",
      headers,
      "POST",
      null,
      requestBody
    );
  }

  static subjectCreateDevice(subjectId, headers = {}) {
    const requestBody = { subjectId };
    return RequestHelper.send(
      serverAddress + "/devices",
      headers,
      "POST",
      null,
      requestBody
    );
  }
  static getOnboardingCode(deviceId) {
    return RequestHelper.send(
      serverAddress + "/devices/" + deviceId + "/onboardingCode",
      { 'Accept': CONTENT_TYPE.TEXT_PLAIN },
      "GET"
    );
  }

  static getQrCode(deviceId) {
    return RequestHelper.send(
      serverAddress + "/devices/" + deviceId + "/qrcode",
      {
        "Content-Type": CONTENT_TYPE.APPLICATION_OCTETSTREAM,
      },
      "GET",
      undefined,
      undefined,
      {
        headers: {
          useEmptyAccept: true,
        },
      }
    );
  }

  static sendQrCodeEmail(
    deviceId,
    email,
    language,
    shouldOverwriteEmail,
    shouldOverwriteLanguage
  ) {
    return RequestHelper.send(
      serverAddress + "/devices/" + deviceId + "/qrcode",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      { email, language, shouldOverwriteEmail, shouldOverwriteLanguage }
    );
  }

  static sendActivationEmail(
      subjectId
  ) {
    return RequestHelper.send(
        serverAddress + "/subjects/" + subjectId + "/send-staff-invoked-activation-email",
        { "Accept-Language": InternationalisationService.getLanguage() },
        "POST",
        null,
        {}
    );
  }

static getSubjectQuestionnairesCompletedOnly(
    subjectId,
    definitionCode,
    completedFrom,
    completedTo,
    maxResults
  ){
    return this.getSubjectQuestionnaires(
      subjectId,
      definitionCode,
      true,
      completedFrom,
      completedTo,
      undefined,
      undefined,
      undefined,
      undefined,
      maxResults,
      undefined
    )
  }


static getSubjectQuestionnairesActiveNotCompletedOnly(
    subjectId,
    definitionCode,
    activeFrom,
    activeTo,
    maxResults
  ){
    return this.getSubjectQuestionnaires(
      subjectId,
      definitionCode,
      false,
      undefined,
      undefined,
      false,
      activeFrom,
      activeTo,
      'oldestFirst',
      maxResults,
      undefined
    )
  }

static getSubjectQuestionnairesExpiredNotCompletedOnly(
    subjectId,
    definitionCode,
    activeFrom,
    activeTo,
    maxResults
  ){
    return this.getSubjectQuestionnaires(
      subjectId,
      definitionCode,
      false,
      undefined,
      undefined,
      true,
      activeFrom,
      activeTo,
      undefined,
      maxResults,
      undefined
    )
  }

static getSubjectQuestionnairesCountAll(
    subjectId,
    definitionCode
  ){
    return this.getSubjectQuestionnaires(
      subjectId,
      definitionCode,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      0,
      undefined
    )
  }
static getSubjectQuestionnaireById(
    subjectId,
    questionnaireId
  ){
return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/questionnaires/instance/" +
        questionnaireId,
      {},
      "GET",
      {}
    );
}
static getSubjectQuestionnaireChildren(
    subjectId,
    questionnaireId
  ){
return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/questionnaires/instance/" +
        questionnaireId + "/children",
      {},
      "GET",
      {}
    );
}

static getSubjectQuestionnairesAllById(
  subjectId
){
return RequestHelper.send(
    serverAddress +
      "/subjects/" +
      subjectId +
      "/questionnaires/all", 
    {},
    "GET",
    {}
  );
}


static getSubjectQuestionnairesByCode(
    subjectId,
    definitionCode,
    rangeFromVar,
    rangeToVar,
    isActive,
    isComplete,
    sortOrder
){
    let rangeFrom, rangeTo;
    if (rangeFromVar) {
      if (moment.isMoment(rangeFromVar)) {
        rangeFrom = DateTimeService.internationalDate.fromDate(rangeFromVar);
      } else {
        rangeFrom = rangeFromVar;
      }

    }
    if (rangeToVar) {
      if (moment.isMoment(rangeToVar)) {
        rangeTo = DateTimeService.internationalDate.fromDate(rangeToVar);
      } else {
        rangeTo = rangeToVar;
      }
    }

    return RequestHelper.send(
        serverAddress +
        "/subjects/" +
        subjectId +
        "/questionnaires/" +
        definitionCode +
        "/data-table",
        {},
        "GET",
        {
          rangeFrom,
          rangeTo,
          isActive,
          isComplete,
          sortOrder
        }
    );
}
static getSubjectQuestionnairesByCodeAndWorkflowTask(
    subjectId,
    definitionCode,
    workflowTask,
    sortOrder
){


    return RequestHelper.send(
        serverAddress +
        "/subjects/" +
        subjectId +
        "/questionnaires/" +
        definitionCode +
        "/data-table/" +
        workflowTask,
        {},
        "GET",
        {
          sortOrder
        }
    );
}

static getSubjectQuestionnairesActive(
    subjectId,
    definitionCode,
    rangeFrom,
    rangeTo,
){
  return this.getSubjectQuestionnairesByCode(
      subjectId,
      definitionCode,
      rangeFrom,
      rangeTo,
      true,
      false,
  )
}

static getSubjectQuestionnairesMissed(
    subjectId,
    definitionCode,
    rangeFrom,
    rangeTo,
){
  return this.getSubjectQuestionnairesByCode(
      subjectId,
      definitionCode,
      rangeFrom,
      rangeTo,
      false,
      false,
  )
}

static getSubjectQuestionnairesComplete(
    subjectId,
    definitionCode,
    rangeFrom,
    rangeTo,
){
    // isActive has no effect when isComplete is true
  return this.getSubjectQuestionnairesByCode(
      subjectId,
      definitionCode,
      rangeFrom,
      rangeTo,
      false,
      true,
  )
}


static getSubjectQuestionnaireByCodeAndId(
    subjectId,
    definitionCode,
    questionnaireId
  ){
    return this.getSubjectQuestionnaires(
      subjectId,
      definitionCode,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      1,
      questionnaireId
    ).then(response=>{
        return response && response.result && response.result.length > 0 ? response.result[0] : undefined
    })

  }



  static getSubjectQuestionnaires(
    subjectId,
    definitionCode,
    completed,
    completedFrom,
    completedTo,
    expired,
    activeFrom,
    activeTo,
    sortOrder,
    maxResults,
    questionnaireId
  ) {
    const queryParams = {};
    if (completed !== undefined) {
      queryParams.completed = completed;
    }
    if (completedFrom) {
      if (moment.isMoment(completedFrom)) {
        completedFrom = DateTimeService.internationalDate.fromDate(completedFrom);
      }
      queryParams.from = completedFrom;
    }
    if (completedTo) {
      if (moment.isMoment(completedTo)) {
        completedTo = DateTimeService.internationalDate.fromDate(completedTo);
      }
      queryParams.to = completedTo;
    }
    if (expired === true) {
      queryParams.closingTo = moment().format('YYYY-MM-DD')
    }
    if (expired === false) {
      queryParams.closingFrom = moment().format('YYYY-MM-DD')
    }

    if (activeFrom) {
      if (moment.isMoment(activeFrom)) {
        activeFrom = DateTimeService.internationalDate.fromDate(activeFrom);
      }
      queryParams.activeFrom = activeFrom;
    }
    if (activeTo) {
      if (moment.isMoment(activeTo)) {
        activeTo = DateTimeService.internationalDate.fromDate(activeTo);
      }
      queryParams.activeTo = activeTo;
    }
    if (maxResults !== undefined) {
      queryParams.maxResults = maxResults;
    }
    if (sortOrder) {
      queryParams.order = sortOrder;
    }
    if (questionnaireId) {
      queryParams.questionnaireId = questionnaireId;
    }
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/questionnaires/" +
        definitionCode,
      {},
      "GET",
      queryParams
    );
  }
  static createQuestionnaire(
    subjectId,
    definitionCode,
    answers,
    queryParams = {}
  ) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/questionnaires/" +
        definitionCode,
      {},
      "POST",
      queryParams,
      answers
    );
  }

  static createSupportQuestionnaire(
    subjectId,
    definitionCode,
    answers,
    queryParams = {}
  ) {
    return RequestHelper.send(
      serverAddress +
      "/subjects/" +
      subjectId +
      "/questionnaires/" +
      definitionCode +
      "/support",
      {},
      "POST",
      queryParams,
      answers
    );
  }

  static getScreeningQuestionnaires(
    questionnaireDefinitions,
    selfOnboardingConfig
  ) {
    let screeningQuestionnaires = [];
    if (selfOnboardingConfig && selfOnboardingConfig.screeningQuestionnaires) {
      selfOnboardingConfig.screeningQuestionnaires.forEach((sq, index) => {
        const foundQuestionnaire = questionnaireDefinitions.find(
          (qDef) => qDef.code === sq
        );
        if (foundQuestionnaire) {
          screeningQuestionnaires.push(foundQuestionnaire);
        }
      });
    }
    return screeningQuestionnaires;
  }

  static doesSubjectNeedToCompleteScreeningQuestionnaires() {
    return RequestHelper.send(
      serverAddress +
        "/subjects/needtocompletescreeningquestionnaires",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static doesSubjectExistWithCode(subjectCode) {
    return RequestHelper.send(
        serverAddress + "/subjects/exists/" + subjectCode
    );
  }

  static doesSubjectExistWithEmail(subjectCode) {
    return RequestHelper.send(
        serverAddress + "/subjects/exists/email/" + subjectCode
    );
  }

  static async getPreferredNotificationTime() {
    let value = await RequestHelper.send(
      serverAddress + "/subjects/notificationtime",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      null
    );
    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    }
    return value;
  }
  static updatePreferredNotificationTime(hour) {
    RequestHelper.send(
      serverAddress + "/subjects/notificationtime",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      { notificationTime: hour }
    );
  }

  static async getSubjectPathway(subjectId) {
    const subjectData = await SubjectService.getSubjectData(subjectId);
    if (!subjectData) {
      return undefined;
    }

    const trial = await TrialService.getCurrentTrial();
    if (!trial) {
      return undefined;
    }

    const configPathwayQuestionnaire = trial.pathwayQuestionnaire;
    const configPathwayQuestion = trial.pathwayQuestion;

    if (configPathwayQuestionnaire && configPathwayQuestion) {
      const definitionWithPathway = subjectData.subjectRecords.find(
        (record) => record.definition.code === configPathwayQuestionnaire
      );
      let pathwayAnswer = definitionWithPathway?.answers[configPathwayQuestion];

      if (!Array.isArray(pathwayAnswer)) {
        pathwayAnswer = [pathwayAnswer];
      }
      return pathwayAnswer;
    }
    return undefined;
  }

  static canChangePassword() {
    return RequestHelper.send(
      serverAddress + "/subject/canChangePassword"
    );
  }

  static async autoSelectSubjectLanguage() {
    const subjectPreferences = await this.getSubjectPreferences();
    const languageCode = subjectPreferences?.language?.code;
    if (languageCode !== undefined) {
      await InternationalisationService.changeLanguage(languageCode);
    }
  }

  static randomise(subjectId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/randomise",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST"
    );
  }

  static getSubjectCode() {
    return RequestHelper.send(
      serverAddress + "/subjects/subjectcode/"
    );
  }

  static async getSubjectsByLookup(subjectCode) {
    const url = `${serverAddress}/subjects/lookup`;
    const requestBody = {
      subjectCode: subjectCode != null ? subjectCode.trim() : ""
    };
    return RequestHelper.send(
      url,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      requestBody
    );
  }

  static listAndCreateOutstandingMandatoryProsForLinkedStaff() {
    return RequestHelper.send(
        serverAddress +
        "/subjects/listAndCreateOutstandingMandatoryProsForLinkedStaff",
        {},
        "GET",
        {}
    );
  }

  static async shouldShowScreeningQuestionnaire() {
    let showScreeningQuestionnaires = false;

    if (AuthService.getAccountType() === "subject") {
      await SubjectService.autoSelectSubjectLanguage();

      let hasScreeningQuestionnaires = false;

      const questionnaireDefinitions =
          await SubjectService.getQuestionnaireDefinitions("EVENT");
      if (questionnaireDefinitions) {
        const screeningQuestionnaires =
            SubjectService.getScreeningQuestionnaires(
                questionnaireDefinitions,
                Window.configuration.ui.selfOnboarding
            );
        hasScreeningQuestionnaires =
            screeningQuestionnaires && screeningQuestionnaires.length > 0;
      }

      if (hasScreeningQuestionnaires) {
        showScreeningQuestionnaires =
            await SubjectService.doesSubjectNeedToCompleteScreeningQuestionnaires();
      }

      return showScreeningQuestionnaires;
    }
  }

  static clearSecurityInfo(subjectId) {
    return RequestHelper.send(
      serverAddress +
        "/subjects/" +
        subjectId +
        "/securityInfo",
      {},
      "DELETE"
    );
  }
}
