import React, { useContext, useMemo } from "react";
import { Route } from "react-router-dom";
import StaffListPage from "../pages/staff/StaffListPage";
import StaffCreateAndEditPage from "../pages/staff/StaffCreateAndEditPage";
import SubjectCreatePage from "../pages/subjects/SubjectCreatePage";
import SubjectListPage from "../pages/subjects/SubjectListPage";
import DrugListPage from "../pages/drugs/DrugListPage";
import DrugCreatePage from "../pages/drugs/DrugCreatePage";
import DrugEditPage from "../pages/drugs/DrugEditPage";
import GroupsListPage from "../pages/groups/GroupsListPage";
import GroupsNewPage from "../pages/groups/GroupsNewPage";
import GroupsEditPage from "../pages/groups/GroupsEditPage";
import AdminUtilsPage from "../pages/utils/AdminUtilsPage";
import AdminUtilsDiagnosticsPage from "../pages/utils/AdminUtilsDiagnosticsPage";
import ExportListPage from "../pages/export/ExportListPage";
import ManageRewardsPage from "../pages/rewards/ManageRewardsPage";
import AdminTranslationsPage from "../pages/utils/AdminTranslationsPage";
import DataQueryListPage from "../pages/dataqueries/DataQueryListPage";
import SubjectPage from "../pages/subjects/SubjectPage";
import AdminTriggerListPage from "../pages/utils/AdminTriggerListPage";
import AdminLookupTool from "../pages/utils/AdminLookupTool";
import AdminAttachmentMonitoringPage from "../pages/utils/AdminAttachmentMonitoringPage";
import AdminAttachmentRandomisationPage from "../pages/utils/AdminAttachmentRandomisationPage";
import AdminCacheManagementPage from "../pages/utils/AdminCacheManagementPage";
import GroupsUpdateMetadataPage from "../pages/groups/GroupsUpdateMetadataPage";
import AdminTriggerCreateEditPage from "../pages/utils/AdminTriggerCreateEditPage";
import RolesListPage from "../pages/roles/RolesListPage";
import RolesNewPage from "../pages/roles/RolesNewPage";
import RolesEditPage from "../pages/roles/RolesEditPage";
import AdminQuestionnaireDefinitionListPage from "../pages/utils/AdminQuestionnaireDefinitionListPage";
import AdminQuestionnaireDefinitionCreatePage from "../pages/utils/AdminQuestionnaireDefinitionCreatePage";
import AdminQuestionnaireDefinitionCreateEditPage from "../pages/utils/AdminQuestionnaireDefinitionCreateEditPage";
import AdminQuestionDefinitionCreateEditPage from "../pages/utils/AdminQuestionDefinitionCreateEditPage";
import AdminConfigToolsListPage from "../pages/utils/AdminConfigToolsListPage";
import AdminConfigToolsCreateOrEditPage from "../pages/utils/AdminConfigToolsCreateOrEditPage";
import AttachmentSearch from "../pages/attachments/AttachmentSearch";
import BatchUploadIndex from "../pages/attachments/BatchUpload/BatchUploadIndex";
import AdminNotificationListPage from "../pages/utils/AdminNotificationListPage";
import NotificationListPage from "../pages/notifications/NotificationListPage";
import DashboardReportPage from "../pages/reports/DashboardReportPage";
import AdminTechSupportPage from "../pages/utils/AdminTechSupportPage";
import AuditSearch from "../pages/audit/AuditSearch";
import ScreenshotsListPage from "../pages/screenshots/ScreenshotsListPage";
import AdminColumnOverridesPage from "../pages/utils/AdminColumnOverridesPage";
import AdminAttachmentBlurringPage from "../pages/utils/AdminAttachmentBlurringPage";
import RolesPermissionsPage from "../pages/staff/RolesPermissionsPage";
import AdminDigestAlertListPage from "../pages/utils/AdminDigestAlertListPage";
import AdminQuestionnaireDefinitionFullEditPage from "../pages/utils/AdminQuestionnaireDefinitionFullEditPage";
import AdminRemoteJobProcessingQueueListPage from "../pages/utils/AdminRemoteJobProcessingQueueListPage";
import BatchUploadFileSelector from "../pages/attachments/BatchUpload/BatchUploadFileSelector";
import BatchUploadProcessData from "../pages/attachments/BatchUpload/BatchUploadProcessData";
import AdminQuestionnaireWorkflowPage from "../pages/utils/AdminQuestionnaireWorkflowPage";
import AdminRecalculateAnswersPage from "../pages/utils/answers/AdminRecalculateAnswersPage";
import VisitDateManagement from "../pages/visitDates/VisitDateManagement";
import AdminModulesEditPage from "../pages/utils/AdminModulesEditPage";
import UserContext from "../context/UserContext";
import AdminStudyManagementPage from "../pages/utils/AdminStudyManagementPage";
import AdminSimpleTranslationsPage from "../pages/utils/AdminSimpleTranslationsPage";
import AdminSimpleTranslationsQuestionnairePage from "../pages/utils/AdminSimpleTranslationsQuestionnairePage";
import StaffService from "../StaffService";


const ROUTE_DEFINITIONS = [
  { path: "/app/dashboard-report/:code", component: DashboardReportPage },
  { path: "/app/rewards", isExact: true, component: ManageRewardsPage, permission: 'MANAGE_REWARDS', allowSuperAdmin: true },

  { path: "/app/staff", isExact: true, component: StaffListPage, permission: 'VIEW_STAFF', allowSuperAdmin: true },
  { path: "/app/staff/new", isExact: true, component: StaffCreateAndEditPage, permission: 'CREATE_STAFF', allowSuperAdmin: true },
  { path: "/app/staff/edit/:id", isExact: true, component: StaffCreateAndEditPage, permission: 'CREATE_STAFF', allowSuperAdmin: true },

  { isExact: true, path: "/app/subjects/:groupCode", component: SubjectListPage },
  { isExact: true, path: "/app/subjects/:groupCode/new", component: SubjectCreatePage },
  { path: "/app/subject/:subjectId", component: SubjectPage }, // Nested Routes
  { isExact: true, path: "/app/drugs", component: DrugListPage },
  { isExact: true, path: "/app/drugs/new", component: DrugCreatePage },
  { isExact: true, path: "/app/drugs/edit/:id", component: DrugEditPage },
  { isExact: true, path: "/app/groups", component: GroupsListPage, permission: 'MANAGE_GROUPS', allowSuperAdmin: true },
  { isExact: true, path: "/app/groups/new", component: GroupsNewPage },
  { isExact: true, path: "/app/groups/edit/:id", component: GroupsEditPage },
  { isExact: true, path: "/app/groups/updatemetadata/:id", component: GroupsUpdateMetadataPage },
  { isExact: true, path: "/app/roles", component: RolesListPage, permission: 'MANAGE_ROLES', allowPrimarySuperAdmin: true },
  { isExact: true, path: "/app/roles/new", component: RolesNewPage, permission: 'MANAGE_ROLES', allowPrimarySuperAdmin: true },
  { isExact: true, path: "/app/roles/edit/:id", component: RolesEditPage, permission: 'MANAGE_ROLES', allowPrimarySuperAdmin: true },

  { isExact: true, path: "/app/utils", component: AdminUtilsPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/translations", component: AdminTranslationsPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/attachment-monitoring", component: AdminAttachmentMonitoringPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/attachment-randomisation", component: AdminAttachmentRandomisationPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/attachment-blurring", component: AdminAttachmentBlurringPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/cache-management", component: AdminCacheManagementPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/tech-support", component: AdminTechSupportPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/notification-auditactivity", component: AdminNotificationListPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/remotejob-processing-queues", component: AdminRemoteJobProcessingQueueListPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/config-tool", component: AdminConfigToolsListPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/column-overrides", component: AdminColumnOverridesPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/questionnaire-workflow", component: AdminQuestionnaireWorkflowPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/config-tool/new", component: AdminConfigToolsCreateOrEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/diagnostics", component: AdminUtilsDiagnosticsPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/triggers/new", component: AdminTriggerCreateEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/triggers/edit/:triggerId", component: AdminTriggerCreateEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/triggers", component: AdminTriggerListPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/digest-alerts", component: AdminDigestAlertListPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/lookup", component: AdminLookupTool, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/questionnaire-definitions/new", component: AdminQuestionnaireDefinitionCreatePage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/questionnaire-definitions/edit/:questionnaireDefinitionId", component: AdminQuestionnaireDefinitionCreateEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/questionnaire-definitions/full-edit/:questionnaireDefinitionId", component: AdminQuestionnaireDefinitionFullEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/questionnaire-definitions/edit/:questionnaireDefinitionId/new-question", component: AdminQuestionDefinitionCreateEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/questionnaire-definitions", component: AdminQuestionnaireDefinitionListPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/modules", component: AdminModulesEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/question-definitions/edit/:questionDefinitionId", component: AdminQuestionDefinitionCreateEditPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/answers/recalculate", component: AdminRecalculateAnswersPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/utils/study-management", component: AdminStudyManagementPage, allowSuperAdmin: true },
  { isExact: true, path: "/app/exports", component: ExportListPage, permission: 'MANAGE_EXPORTS', allowSuperAdmin: true },
  { isExact: true, path: "/app/queries", component: DataQueryListPage, permission: 'VIEW_DATA_QUERIES', allowSuperAdmin: true },
  { isExact: true, path: "/app/notifications", component: NotificationListPage, permission: 'VIEW_NOTIFICATIONS', allowSuperAdmin: true },

  { isExact: true, path: "/app/search/attachments", component: AttachmentSearch, permission: 'SEARCH_ATTACHMENTS' },
  { isExact: true, path: "/app/attachments/batchupload", component: BatchUploadIndex, permission: 'BATCH_ATTACHMENTS_VIEW', allowSuperAdmin: true },
  { isExact: true, path: "/app/visitDates/manage/trial", component: VisitDateManagement, permission: 'MANAGE_TRIAL_VISITDATES' },
  { isExact: true, path: "/app/visitDates/manage/site/:groupCode", component: VisitDateManagement }, // Per Site - handled in the VisitDateManagement component
  { isExact: true, path: "/app/attachments/batchupload/uploadfiles", component: BatchUploadFileSelector, permission: 'BATCH_ATTACHMENTS_UPLOAD_FILES' },
  { isExact: true, path: "/app/attachments/batchupload/processdata", component: BatchUploadProcessData, permission: 'BATCH_ATTACHMENTS_PROCESS_DATA' },
  { isExact: true, path: "/app/search/audit", component: AuditSearch, permission: 'SEARCH_AUDIT' },
  { isExact: true, path: "/app/screenshots", component: ScreenshotsListPage, permission: 'MANAGE_SCREENSHOTS', allowSuperAdmin: true },
  { isExact: true, path: "/app/permissions", component: RolesPermissionsPage },
  { isExact: true, path: "/app/translations-simplified", component: AdminSimpleTranslationsPage },
  { isExact: true, path: "/app/translations-simplified/:questionnaireDefinitionCode", component: AdminSimpleTranslationsQuestionnairePage },
];

const StaffOnlyRoutes = () => {
  const { profile, accountType } = useContext(UserContext);
  const shouldRenderStaffOnlyRoutes = accountType === "staff" && profile != null;

  const checkPermission = (route) => {
    if (route.allowPrimarySuperAdmin === true && StaffService.isPrimarySuperAdmin(profile)) {
      return true;
    }
    if (route.allowSuperAdmin === true && profile.superAdmin === true) {
      return true;
    }
    if (route.permission == null) {
      return true;
    }
    return profile.helpers.hasPermissionInAnyGroup(route.permission);
  }

  const getRoutes = () => {
    if (!shouldRenderStaffOnlyRoutes) {
      // Don't render this for non-staff!
      // Do not logout, as it will log out for normal use when this component is rendered, so we just do not add the routes to handle these URLs when not logged in as staff members
      return null;
    }

    return ROUTE_DEFINITIONS.map(route => {
      const hasPermission = checkPermission(route);
      if (hasPermission !== true) {
        return null;
      }

      return <Route
        key={route.path}
        exact={route.isExact}
        path={route.path}
        component={route.component}
      />
    })
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routesToRender = useMemo(getRoutes, [profile, accountType]);

  return <>{routesToRender}</>;
};

export default StaffOnlyRoutes;
