import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import VanillaJsonEditor from './VanillaJsonEditor';

const JsonEditorModal = ({
  t,
  title,
  onConfirm,
  onCancel,
  isOpen,
  value
}) => {
  const [isValid, setIsValid] = useState(true);
  const [valueAsJsonString, setValueAsJsonString] = useState();

  useEffect(() => {
    const parseInputValue = (val) => {
      if (val == null) {
        return '';
      }
      if (typeof val === 'string') {
        return val;
      }

      try {
        return JSON.stringify(val);
      } catch (error) {
        console.error('[JsonEditorModal][parseInputValue] Could not stringify JSON object');
      }
      return val;
    };

    setValueAsJsonString(parseInputValue(value));
  }, [value]);

  const handleJsonChange = useCallback((content, _previousContent, status) => {
    const valid = status?.contentErrors == null;
    setIsValid(valid);

    const jsonString = content?.json != null ? JSON.stringify(content?.json) : content?.text;
    setValueAsJsonString(jsonString);
  }, []);

  if (!isOpen) {
    return null;
  }

  const handleConfirm = () => {
    if (onConfirm != null) {
      onConfirm(valueAsJsonString);
    };
  }

  const handleCancel = () => {
    if (onCancel != null) {
      onCancel();
    };
  }

  return (
    <Modal
      size={'large'}
      open={isOpen}
      closeIcon={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header>
        <h3>{t('JSON_EDIOR_MODAL', title)}</h3>
      </Modal.Header>
      <Modal.Content scrolling>
        <VanillaJsonEditor
          fluid
          content={{ text: valueAsJsonString }}
          readOnly={false}
          onChange={handleJsonChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={!isValid} primary onClick={handleConfirm}>{t(['GLOBAL_BUTTON_CONFIRM'], 'Confirm')}</Button>
        <Button onClick={handleCancel}>{t(['GLOBAL_BUTTON_CANCEL'], 'Cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(JsonEditorModal);
